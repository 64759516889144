<template>
  <div class="container">
    <div class="nps-chart-values columns is-centered">
      <div class="nps-chart column is-7">
        <apexchart
          type="bar"
          :options="barChartOptions()"
          :series="barChartSeries()"
        />
      </div>
      <div class="nps-panel column is-4">
        <div class="nps-groups">
          <div class="nps-group">
            <div class="quantity nps-detractors">
              {{ detractors() }}
            </div>
            <div class="text">
              Detractores
            </div>
            <div class="percentage">
              {{ detractorsPercentage() }}%
            </div>
          </div>
          <div class="nps-group">
            <div class="quantity nps-passives">
              {{ passives() }}
            </div>
            <div class="text">
              Pasivos
            </div>
            <div class="percentage">
              {{ passivesPercentage() }}%
            </div>
          </div>
          <div class="nps-group">
            <div class="quantity nps-promoters">
              {{ promoters() }}
            </div>
            <div class="text">
              Promotores
            </div>
            <div class="percentage">
              {{ promotersPercentage() }}%
            </div>
          </div>
        </div>
        <div class="nps-score">
          <apexchart
            type="radialBar"
            :options="radialChartOptions()"
            :series="radiarChartSeries()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export const NPSColors = Object.freeze({
  DETRACTORS: '#ee5443',
  PASSIVES: '#ffcc35',
  PROMOTERS: '#7bb443'
})

export default {
  name: 'TaskChartNPS',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  computed: {
    series() {
      return Object.values(this.task.data.response_option_count)
    }
  },
  methods: {
    score() {
      return this.promotersPercentage() - this.detractorsPercentage()
    },

    radialScore() {
      return (this.score() + 100) / 2
    },

    radiarChartSeries() {
      return [this.radialScore()]
    },

    barChartSeries() {
      return [{ data: this.series }]
    },

    detractors() {
      const values = this.series.slice(0, 6)
      return values.reduce((a, b) => a + b, 0)
    },

    passives() {
      const values = this.series.slice(6, 8)
      return values.reduce((a, b) => a + b, 0)
    },

    promoters() {
      const values = this.series.slice(8, 10)
      return values.reduce((a, b) => a + b, 0)
    },

    total() {
      return this.series.reduce((a, b) => a + b, 0)
    },

    detractorsPercentage() {
      return this.getPercentage(this.detractors(), this.total())
    },

    passivesPercentage() {
      return this.getPercentage(this.passives(), this.total())
    },

    promotersPercentage() {
      return this.getPercentage(this.promoters(), this.total())
    },

    getPercentage(value, total) {
      return total === 0 ? 0 : Math.round((value / total) * 100)
    },

    getScoreColor() {
      const score = this.score()

      if (score > 30) {
        return NPSColors.PROMOTERS
      }

      if (score >= 0) {
        return NPSColors.PASSIVES
      }

      return NPSColors.DETRACTORS
    },

    getRadialChartColors() {
      return [this.getScoreColor()]
    },

    radialChartOptions() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '12px',
                color: '#555',
                offsetY: 20
              },
              value: {
                offsetY: -10,
                fontSize: '36px',
                color: this.getScoreColor(),
                formatter(val) {
                  return val * 2 - 100
                }
              }
            }
          }
        },
        fill: {
          colors: this.getRadialChartColors()
        },
        stroke: {
          dashArray: 2
        },
        labels: ['NPS'],
        tooltip: {
          enabled: false
        }
      }
    },

    barChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        colors: [
          NPSColors.DETRACTORS,
          NPSColors.DETRACTORS,
          NPSColors.DETRACTORS,
          NPSColors.DETRACTORS,
          NPSColors.DETRACTORS,
          NPSColors.DETRACTORS,
          NPSColors.PASSIVES,
          NPSColors.PASSIVES,
          NPSColors.PROMOTERS,
          NPSColors.PROMOTERS],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          labels: {
            style: {
              colors: [
                NPSColors.DETRACTORS,
                NPSColors.DETRACTORS,
                NPSColors.DETRACTORS,
                NPSColors.DETRACTORS,
                NPSColors.DETRACTORS,
                NPSColors.DETRACTORS,
                NPSColors.PASSIVES,
                NPSColors.PASSIVES,
                NPSColors.PROMOTERS,
                NPSColors.PROMOTERS],
              fontSize: '12px'
            }
          }
        },
        tooltip: {
          enabled: false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nps-panel {

  .nps-groups {
    margin-top: 20px;

    .nps-group {
      margin-bottom: 12px;
      position: relative;

      .quantity {
        display: inline-block;
        position: absolute;
        left: -6px;
        height: 32px;
        width: 32px;
        border-radius: 16px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        line-height: 30px;

        &.nps-detractors {
          background-color: #ee5443;
        }

        &.nps-passives {
          background-color: #ffcc35;
        }

        &.nps-promoters {
          background-color: #7bb443;
        }
      }

      .text {
        display: inline-block;
        width: 100%;
        background-color: #e6e6e6;
        padding-left: 30px;
        margin-top: 1px;
        line-height: 30px;
        border-radius: 15px;
        color: #7f8183;
      }

      .percentage {
        position: absolute;
        right: 10px;
        top: 3px;
        color: #7f8183;
        font-weight: bold;
        line-height: 26px;
      }
    }

  }
}

@media print {
  .nps-panel {
    .nps-groups {
      padding-left: 25px;
    }
  }
}
</style>
