<template>
  <b-table
    :data="participationsData"
    :loading="loading"
    paginated
    backend-pagination
    :total="totalParticipationCount"
    :per-page="pageSize"
    aria-next-label="Siguiente"
    aria-previous-label="Anterior"
    aria-page-label="Página"
    aria-current-label="Página actual"
    detailed
    detail-key="identifier"
    :has-detailed-visible="hasDetailedVisible"
    @details-open="detailsOpen"
    @page-change="onPageChange"
  >
    <b-table-column
      v-slot="props"
      field="identifier"
      label="Identificador"
    >
      {{ props.row.identifier }}
    </b-table-column>
    <b-table-column
      v-if="!isAnonymousProject"
      v-slot="props"
      field="name"
      label="Nombre"
    >
      {{ getName(props.row) }}
    </b-table-column>
    <b-table-column
      v-if="!isAnonymousProject"
      v-slot="props"
      field="email"
      label="Email"
    >
      {{ getEmail(props.row) }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="status"
      label="Estado"
    >
      <participation-status-indicator :participation-data="props.row" />
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="status"
      label="Respuestas totales"
    >
      {{ props.row.responseCount }}
    </b-table-column>
    <template
      #detail="props"
    >
      <div
        v-if="loadingParticipationDetails(props.row.identifier)"
        class="progress-container"
      >
        <b-progress
          size="is-small"
          type="is-primary"
        />
      </div>
      <participation-details
        v-else
        :participation-data="participationDetailsData[props.row.identifier]"
      />
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ParticipationStatusIndicator from '@/components/status-indicators/ParticipationStatusIndicator.vue'
import ParticipationDetails from './ParticipationDetails.vue'

export default {
  name: 'Listing',
  components: {
    ParticipationDetails,
    ParticipationStatusIndicator
  },
  props: {
    taskResponses: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('Projects', ['isAnonymousProject']),
    ...mapGetters('staffActivityParticipations', ['loadingParticipationDetails']),
    ...mapState('Projects', ['projectData']),
    ...mapState('staffActivityParticipations', [
      'participationsData', 'loading', 'totalParticipationCount',
      'pageSize', 'participationDetailsData'
    ])
  },
  async created() {
    await this.$store.dispatch(
      'staffActivityParticipations/getInitialParticipationsData',
      {
        projectIdentifier: this.projectData.identifier,
        activityIdentifier: this.$route.params.activityIdentifier
      }
    )
  },
  methods: {
    async onPageChange(page) {
      await this.$store.dispatch('staffActivityParticipations/getParticipationsPage', { page })
    },
    getEmail(participationData) {
      return participationData.participant.user ? participationData.participant.user.email : ''
    },
    getName(participationData) {
      if (participationData.participant.user) {
        const { firstName, lastName } = participationData.participant.user

        return `${firstName} ${lastName}`
      }

      return '&nbsp;'
    },
    async detailsOpen(params) {
      await this.$store.dispatch(
        'staffActivityParticipations/getParticipationDetails',
        { participationIdentifier: params.identifier }
      )
    },
    hasDetailedVisible(participationData) {
      return participationData.responseCount > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-container {
  display: flex;
  justify-content: center;

  ::v-deep .progress-wrapper {
    width: 100%;
    max-width: 400px;
  }
}
</style>
