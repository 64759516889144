<template>
  <div class="activity-summary-container">
    <b-loading
      v-if="loading"
      :is-full-page="true"
      :active="true"
    />
    <template v-else>
      <div class="header project-header extra-top-padding">
        <div class="header-title wide-column">
          <div class="header-content">
            <div class="pre-title">
              <h3>
                Avance de la actividad
              </h3>
            </div>
            <div class="title">
              <h1>{{ activityData.name }}</h1>
            </div>
            <div class="sub-title">
              <h2 v-if="completedParticipations === 0">
                Ningún participante completó la actividad
              </h2>
              <h2 v-else-if="completedParticipations === 1">
                <span>1</span> participante completó la actividad
              </h2>
              <h2 v-else>
                <span>{{ completedParticipations }}</span> participantes completaron la actividad
              </h2>
            </div>
          </div>
          <div
            v-if="showDownload"
            class="header-actions"
          >
            <export-status-indicator v-if="processingExports" />
            <div class="dropdown-container">
              <div class="dropdown dropdown-menu-animation download is-bottom-left is-mobile-modal">
                <div
                  v-click-outside="hideDownloadMenu"
                  role="button"
                  aria-haspopup="true"
                  class="dropdown-trigger"
                  @click="toggleDownloadMenu()"
                >
                  <b-button class="action-button">
                    <download-icon />
                  </b-button>
                </div>
                <div
                  v-if="showDownloadMenu"
                  class="dropdown-menu"
                >
                  <div
                    role="list"
                    class="dropdown-content"
                  >
                    <a
                      role="listitem"
                      tabindex="0"
                      :class="{ 'dropdown-item': true, disabled: processingExports }"
                      @click="downloadCSV"
                    >
                      <div class="download-icon csv">
                        <img
                          src="../assets/project/icon-excel.svg"
                          alt="Descargar"
                        >
                      </div>
                      <div class="text">Hoja de cálculo (.csv)</div>
                    </a>
                    <a
                      role="listitem"
                      tabindex="1"
                      class="dropdown-item"
                      @click="print"
                    >
                      <div class="download-icon pdf">
                        <img
                          src="../assets/project/icon-pdf.svg"
                          alt="Descargar"
                        >
                      </div>
                      <div class="text">Documento (.pdf)</div>
                    </a>
                    <a
                      role="listitem"
                      tabindex="2"
                      class="dropdown-item"
                      :href="activityData.mediaBatchDownloadUrl"
                      @click="downloadMedia"
                    >
                      <div class="download-icon media">
                        <img
                          src="../assets/project/icon-media.svg"
                          alt="Descargar"
                        >
                      </div>
                      <div class="text">Recursos (.zip)</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs-container">
          <div class="wide-column tabs">
            <ul>
              <li :class="[isTabSummary ? 'is-active' : '']">
                <a @click="tab = 'summary'">Resumen</a>
              </li>
              <li :class="[ isTabParticipants ? 'is-active' : '']">
                <a @click="tab='participants'">Participantes</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          v-if="isTabSummary"
          class="wide-column page-bottom-padding"
        >
          <div v-if="completedParticipations < 1">
            <div class="section-summary-empty">
              <div class="charts-empty">
                <div class="charts-icon">
                  <img
                    src="../assets/project/icon-charts.svg"
                    alt="Descargar"
                  >
                </div>
                <div class="charts-empty-title">
                  Todavía no hay datos para mostrar
                </div>
                <div class="charts-empty-detail">
                  Aquí podrás visualizar los resultados de tu proyecto.
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="section-title">
              <h3>Preguntas</h3>
            </div>

            <div class="section-panel">
              <task-summary
                v-for="(task, index) in activitySummary.tasks"
                :key="index"
                :task="task"
                :index="index"
                :participant-count="projectData.participantCount"
              />
            </div>
          </div>
        </div>
        <div
          v-if="isTabParticipants"
          class="participants wide-column page-bottom-padding"
        >
          <div v-if="noParticipants">
            <div class="section-summary-empty">
              <div class="charts-empty">
                <div class="charts-icon">
                  <img
                    src="../assets/project/icon-participants.svg"
                    alt="Descargar"
                  >
                </div>
                <div class="charts-empty-title">
                  Todavía no agregaste participantes
                </div>
                <div class="charts-empty-detail">
                  Una vez que los agregues, podrás visualizar sus avances aquí.
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <participation-listing
              :tasks="activityData.tasks"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import ExportStatusIndicator from '@/components/exports/ExportStatusIndicator.vue'
import ParticipationListing from '@/components/project-dashboard/participation-listing/ParticipationListing.vue'
import TaskSummary from '@/components/TaskSummary.vue'

import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import VuePapaParse from 'vue-papa-parse'

Vue.use(VuePapaParse)

export default {
  name: 'ProjectResume',
  components: {
    DownloadIcon,
    ExportStatusIndicator,
    ParticipationListing,
    TaskSummary
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      projectCompletion: 0,
      tab: 'summary',
      showDownloadMenu: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters('dataExports', ['processingExports']),
    ...mapState('Projects', ['projectData']),
    ...mapState('summary', ['activityData', 'activitySummary']),
    completedParticipations() {
      if (!this.activitySummary || !this.activitySummary.completed_participations) return 0

      return this.activitySummary.completed_participations
    },
    isTabSummary() {
      return this.tab === 'summary'
    },
    isTabParticipants() {
      return this.tab === 'participants'
    },
    noParticipants() {
      return this.projectData.participantCount === 0
    },
    showDownload() {
      return this.completedParticipations > 0
    }
  },
  async created() {
    const fetchParams = this.$route.params

    await this.$store.dispatch(
      'Projects/getProjectData',
      { projectIdentifier: fetchParams.projectIdentifier, edit: false }
    )
    await this.$store.dispatch('summary/getSummary', fetchParams)
    await this.$store.dispatch('summary/getActivity', fetchParams)

    this.loading = false
  },
  methods: {
    print() {
      window.print()
      this.toggleDownloadMenu()
    },
    downloadMedia() {
      this.toggleDownloadMenu()
    },
    downloadCSV() {
      if (this.processingExports) return

      this.$store.dispatch(
        'dataExports/startDataExport',
        { activityIdentifier: this.activityData.identifier, fileType: 'csv' }
      )

      this.hideDownloadMenu()
    },
    hideDownloadMenu() {
      if (this.showDownloadMenu) this.showDownloadMenu = false
    },
    toggleDownloadMenu() {
      this.showDownloadMenu = !this.showDownloadMenu
    }
  }
}
</script>

<style lang="scss">
  @import '../scss/project_dashboard.scss';
</style>

<style lang="scss" scoped>
  @import '../scss/activity_summary.scss';
</style>
