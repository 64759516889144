<template>
  <div class="general-container">
    <the-navbar
      :centered="true"
      :return-link="returnLink"
    />
    <activity-summary />
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import ActivitySummary from '@/components/ActivitySummary.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Resumen',
  components: {
    TheNavbar,
    ActivitySummary
  },
  computed: {
    ...mapGetters('Projects', ['isCommunityProject']),
    ...mapState('Projects', ['projectData']),
    returnLink() {
      if (this.projectData) {
        if (this.isCommunityProject) {
          return {
            title: this.projectData.name,
            link: {
              name: 'project-dashboard-summary',
              params: {
                identifier: this.projectData.identifier
              }
            }
          }
        }

        return {
          title: 'Proyectos',
          link: {
            name: 'proyectos'
          }
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.general-container {
  margin-bottom: 0px;
}
</style>

<style lang="scss" scoped>
  @import '@/scss/project_dashboard.scss';
</style>
