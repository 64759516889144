<template>
  <div class="task-panel">
    <div class="task-title">
      {{ taskTitle }}
    </div>
    <div class="task-progress">
      <b-progress
        :type="'is-primary'"
        :size="'small'"
        :value="task.responses_count"
        :max="participantCount"
        :show-value="false"
        :class="'is-small is-info'"
      />
      <div class="progress-text">
        {{ progressMessage }}
      </div>
    </div>
    <div
      v-if="task.responses_count > 0"
      class="task-chart"
    >
      <div
        v-if="isChartTypeCloud"
        class="chart-container"
      >
        <vue-word-cloud
          :style="wordCloudStyle"
          :words="wordCloudWords"
          :color="calculateWordCloudWordColor"
          font-family="Encode Sans"
          :spacing="0.7"
        >
          <template slot-scope="{ text, weight }">
            <div
              :title="getWordTitle(weight)"
              style="cursor: pointer"
            >
              {{ text }}
            </div>
          </template>
        </vue-word-cloud>
      </div>

      <task-chart-NPS
        v-else-if="isNPS"
        :task="task"
      />

      <task-chart-rating
        v-else-if="isRating"
        :task="task"
      />

      <template
        v-else
      >
        <div class="chart-container">
          <apexchart
            width="100%"
            :type="chartType"
            :options="options"
            :series="series"
          />
        </div>
        <template
          v-if="displayCustomOptionDetails"
        >
          <h3>Valores de {{ task.custom_option_text }}</h3>
          <div class="custom-option-word-cloud-container chart-container">
            <vue-word-cloud
              v-if="displayCustomOptionDetails"
              :style="wordCloudStyle"
              :words="wordCloudWords"
              :color="calculateWordCloudWordColor"
              font-family="Encode Sans"
              :spacing="0.7"
            >
              <template slot-scope="{ text, weight }">
                <div
                  :title="getWordTitle(weight)"
                  style="cursor: pointer"
                >
                  {{ text }}
                </div>
              </template>
            </vue-word-cloud>
          </div>
        </template>
      </template>
    </div>
    <div
      v-else
      class="task-empty"
    >
      Todavía no hay datos para mostrar
    </div>
  </div>
</template>

<script>
import { formatTaskTitle } from '@/format'

import sharedConstants from '@/shared/constants.json'
import TaskChartNPS from '@/components/TaskChartNPS.vue'
import TaskChartRating from '@/components/TaskChartRating.vue'
import VueApexCharts from 'vue-apexcharts'
import VueWordCloud from 'vuewordcloud'

export default {
  name: 'TaskSummary',
  components: {
    apexchart: VueApexCharts,
    'vue-word-cloud': VueWordCloud,
    TaskChartNPS,
    TaskChartRating
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    participantCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      wordCloudStyle: {
        height: '300px',
        width: '500px',
        'margin-top': '20px'
      }
    }
  },
  computed: {
    displayCustomOptionDetails() {
      return [
        sharedConstants.TASK_TYPE.SIMPLE_CHOICE,
        sharedConstants.TASK_TYPE.MULTIPLE_CHOICE
      ].includes(
        this.task.task_type
      ) && this.task.custom_option_text
    },
    progressMessage() {
      return (
        `${this.task.responses_count
        }/${
          this.participantCount
        } respuestas`
      )
    },
    chartType() {
      let type = 'bar'

      if (this.task.task_type === sharedConstants.TASK_TYPE.SIMPLE_CHOICE
        || this.task.task_type === sharedConstants.TASK_TYPE.SES) type = 'donut'
      else if (this.task.task_type === sharedConstants.TASK_TYPE.OPEN_QUESTION) type = 'cloud'

      return type
    },

    isChartTypeCloud() {
      return this.chartType === 'cloud'
    },

    isNPS() {
      return this.task.task_type === sharedConstants.TASK_TYPE.NPS
    },

    isRating() {
      return this.task.task_type === sharedConstants.TASK_TYPE.RATING
    },

    taskTitle() {
      return formatTaskTitle(this.task.title)
    },
    wordCloudWords() {
      const words = (
        (
          this.task.task_type === sharedConstants.TASK_TYPE.OPEN_QUESTION
          && [sharedConstants.TASK_RESPONSE_TYPE.ONLY_TEXT, ''].includes(this.task.response_allowed_type)
        )
        || (
          [sharedConstants.TASK_TYPE.SIMPLE_CHOICE, sharedConstants.TASK_TYPE.MULTIPLE_CHOICE].includes(
            this.task.task_type
          ) && this.task.custom_option_text
        )
      )
        ? this.task.data.response_value_word_cloud_data
        : this.task.data.response_media_description_word_cloud_data

      return words ? Object.entries(words) : []
    },
    options() {
      return {
        chart: {
          id: this.task.id,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: Object.keys(this.task.data.response_option_count),
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Encode Sans'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Encode Sans'
            }
          }
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            },
            labels: {
              style: {
                fontSize: '13px'
              }
            }
          }
        },
        labels: Object.keys(this.task.data.response_option_count),
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            columnHeight: '15px'
          },
          pie: {
            customScale: 0.7,
            donut: {
              size: '60%'
            }
          }
        },
        dataLabels: this.getDataLabelsOptions(),
        legend: {
          fontFamily: 'Encode Sans',
          formatter(val) {
            return val.length > 30 ? `${val.substring(0, 30)}...` : val
          }
        },
        colors: ['#0a6cff', '#09dee3', '#faad14', '#00e396', '#ff4560', '#775dd0', '#d2d2d2']
      }
    },
    series() {
      return this.getChartSeries()
    }
  },
  methods: {
    calculateWordCloudWordColor(params) {
      const [, weight] = params

      if (weight > 3) {
        return '#0a6cff'
      }

      if (weight > 2) {
        return '#09DEE3'
      }

      if (weight > 1) {
        return '#FAAD14'
      }

      return '#D2D2D2'
    },
    getChartSeries() {
      if (this.task.task_type === sharedConstants.TASK_TYPE.SIMPLE_CHOICE
        || this.task.task_type === sharedConstants.TASK_TYPE.NPS
        || this.task.task_type === sharedConstants.TASK_TYPE.SES) {
        return Object.values(this.task.data.response_option_count)
      }
      return [{ name: 'Respuestas', data: Object.values(this.task.data.response_option_count) }]
    },

    getDataLabelsOptions() {
      if (this.task.task_type === sharedConstants.TASK_TYPE.SIMPLE_CHOICE
        || this.task.task_type === sharedConstants.TASK_TYPE.SES) {
        return {
          formatter(val) {
            return `${Math.round(val)}%`
          },
          style: {
            fontFamily: 'Encode Sans',
            fontSize: '18px',
            fontWeight: 'normal'
          }
        }
      }
      return { enabled: false }
    },

    getWordTitle(weight) {
      return `Frecuencia: ${weight}`
    }
  }
}
</script>

<style lang="scss" scoped>
.task-panel {
  background-color: #ffffff;
  border: 1.17045px solid #e1e1e1;
  box-shadow: 0px 7.02273px 11.7045px rgba(0, 0, 0, 0.0250765);
  border-radius: 7.02273px;
  padding: 25px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  .task-title {
    font-size: 24px;
    color: #000000;
    margin-bottom: 18px;
  }

  .task-progress {
    position: relative;

    ::v-deep .progress {
      height: 8px;
      max-width: 252px;
    }

    .progress-text {
      position: absolute;
      left: 268px;
      top: -10px;
      font-size: 14px;
      color: #121212;
      user-select: none;
    }
  }

  .task-empty {
    text-align: center;
    padding-top: 10px;
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      max-width: 500px;
    }
  }
}
</style>
