<template>
  <div class="rating-box">
    <div class="option-title">
      {{ name }}
    </div>

    <div class="info">
      <div class="info__top">
        <div class="info__average">
          {{ getAverage }}
        </div>
        <div class="info__stars">
          <div class="info__responses">
            {{ countResponses }} respuestas
          </div>
        </div>
      </div>

      <div class="info__bottom">
        <div
          v-for="index in 5"
          :key="index"
          class="info__row"
        >
          <div class="info__number">
            {{ getReverseIndex(index) }}
          </div>

          <b-tooltip
            :label="getPoints(index)"
            type="is-light"
            position="is-bottom"
          >
            <div class="info__bar">
              <span
                class="fill"
                :style="`width: ${getPercentage(index)}%`"
              />
            </div>
          </b-tooltip>

          <div class="info__percent">
            {{ getPercentage(index) }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskChartRatingItem',
  props: {
    name: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: null
    }
  },
  computed: {
    countResponses() {
      return Object.values(this.values).reduce((a, b) => a + b, 0)
    },
    getAverage() {
      let total = 0
      let average = 0
      Object.values(this.values).forEach((value, index) => {
        total += value * Object.keys(this.values)[index]
      })

      average = parseFloat((total / this.countResponses).toFixed(2))

      return average
    }
  },
  methods: {
    getReverseIndex(index) {
      return 5 - (index - 1)
    },
    getPercentage(index) {
      let total
      const value = this.getValue(index)
      return total === 0 ? 0 : Math.round((value / this.countResponses) * 100)
    },
    getPoints(index) {
      const value = this.getValue(index)
      return value === 1 ? `${value} punto` : `${value} puntos`
    },
    getValue(index) {
      const value = this.values[String((this.getReverseIndex(index)))]
      return value !== undefined ? value : 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .rating-box {
    width: 32%;
    height: 250px;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px 13px;

    &:nth-child(3n+2),
    &:nth-child(3n) {
      margin-left: 2%;
    }

    .option-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 14px;
      color: #121212;
      margin-bottom: 15px;
    }

    .info {
      display: flex;
      flex-flow: column;

      .info__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;

        .info__average {
          font-size: 16px;
          font-weight: 600;
          width: 50px;
          height: 44px;
          background-color: $gray-ultralight;
          border-radius: 6px;
          margin-right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .info__stars {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: flex-start;

          .info__responses {
            font-size: 15px;
            color: #9a9a9a;
            margin-left: 3px;
          }
        }
      }

      .info__bottom {
        font-size: 13px;
        line-height: 13px;

        .info__row {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 7px;

          .info__number {
            width: 21px;
            text-align: center;
            margin-right: 3px;
          }

          .b-tooltip {
            width: 100%;
            flex: 1;

            .info__bar {
              flex: 1;
              height: 19px;
              margin-right: 8px;
              background-color: $gray-ultralight;
              position: relative;
              transition: all .2s ease-in-out;

              &:hover {
                background-color: #f1f1f1;
                transition: all .2s ease-in-out;
              }

              .fill {
                position: absolute;
                left: 0;
                height: 100%;
                background-color: indianred;
              }
            }
          }

          .info__percent {
            width: 30px;
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
          }

          &:nth-child(1) {
            .info__bar {
              .fill {
                background-color: #0A6CFF;
              }
            }
          }

          &:nth-child(2) {
            .info__bar {
              .fill {
                background: #4791ff;
              }
            }
          }

          &:nth-child(3) {
            .info__bar {
              .fill {
                background: #84b5ff;
              }
            }
          }

          &:nth-child(4) {
            .info__bar {
              .fill {
                background: #c2daff;
              }
            }
          }

          &:nth-child(5) {
            .info__bar {
              .fill {
                background: #e6f0ff;
              }
            }
          }
        }
      }
    }
  }
</style>
