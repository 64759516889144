<template>
  <ul class="responses-list">
    <li
      v-for="taskResponse in participationData.taskResponses"
      :key="taskResponse.identifier"
      class="response"
    >
      <div class="response-title">
        {{ getTaskTitle(taskResponse.taskIdentifier) }}
      </div>
      <div class="response-value">
        {{ getTaskValue(taskResponse) }}
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

import { taskResponseToArray } from '@/format'

export default {
  name: 'ParticipationDetails',
  props: {
    participationData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('summary', ['activityData', 'activitySummary', 'activityParticipations']),
    tasks() {
      return this.activityData.tasks
    }
  },
  methods: {
    getTaskTitle(identifier) {
      return this.tasks.find((task) => task.identifier === identifier).title
    },
    getTaskValue(taskResponse) {
      const task = this.tasks.find((t) => t.identifier === taskResponse.taskIdentifier)
      const value = taskResponseToArray(task, taskResponse)

      return Array.isArray(value) ? value.join(', ') : value
    }
  }
}
</script>

<style lang="scss" scoped>
.responses-list {
  margin: 0px 15px;

  .response {
    margin: 0px 15px;
    list-style-type: disc;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 14px;
    }

    .response-title {
      font-weight: 500;
    }
  }
}
</style>
