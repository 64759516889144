<template>
  <div class="rating-container">
    <task-chart-rating-item
      v-for="(values, index) in task.data.response_option_count"
      :key="index"
      :name="index"
      :values="values"
    />
  </div>
</template>

<script>
import TaskChartRatingItem from '@/components/TaskChartRatingItem.vue'

export default {
  name: 'TaskChartRating',
  components: {
    TaskChartRatingItem
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  methods: {
    getName(index) {
      return Object.keys(this.task.data)[index]
    }
  }
}
</script>

<style lang='scss' scoped>
  .rating-container {
    width: 100%;
    display: flex;
    flex-flow: revert;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      flex: auto;
    }
  }
</style>
