<template>
  <b-tag :type="tagClass">
    {{ participationStatusText }}
  </b-tag>
</template>

<script>
import sharedConstants from '@/shared/constants.json'
import { participationStatusText } from '@/format'

export default {
  name: 'ParticipationStatusIndicator',
  props: {
    participationData: {
      type: Object,
      required: true
    }
  },
  computed: {
    tagClass() {
      const {
        PARTICIPATION_STATUS: {
          NOT_STARTED, COMPLETE, IN_PROGRESS, DISQUALIFIED
        }
      } = sharedConstants

      switch (this.participationData.status) {
        case NOT_STARTED:
          return 'is-light'
        case COMPLETE:
          return 'is-success'
        case IN_PROGRESS:
          return 'is-warning is-dark'
        default:
        case DISQUALIFIED:
          return 'is-danger'
      }
    },
    participationStatusText() {
      return participationStatusText(this.participationData.status)
    }
  }
}
</script>
